import { Module, VuexModule, Action } from "vuex-module-decorators";
import Store from "../index";
import { getApiClient } from "@/services/api";

// This module is only for functionality related to the following endpoint:
const endpoint = "scheduleitem";

interface PutScheduleOptions {
    type?: string;
    id?: string;
}

@Module({
    dynamic: true,
    store: Store,
    name: "Scheduleitem",
    namespaced: true
})
export default class Scheduleitem extends VuexModule {
    @Action({})
    async putSchedule(data: PutScheduleOptions) {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .put(`${endpoint}`, data, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({})
    async removeScheduleItem(data: PutScheduleOptions) {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .delete(`${endpoint}/${data.type}/${data.id}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }
}
