import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import Store from "../index";
import {
    AttendeeScheduleDay,
    AttendeeScheduleSlot,
    AttendeeDataObject
} from "@/types/interfaces";
import { getApiClient } from "@/services/api";
import { format, parseISO } from "date-fns";

// This module is only for functionality related to the following endpoint:
const endpoint = "schedule";

/**
 * Format schedule
 *
 * @param data object
 * @return Array<AttendeeScheduleDay>
 */
function formatSchedule(data: Array<AttendeeScheduleSlot>) {
    const returnValue: Array<AttendeeScheduleDay> = [];

    let dates: Array<string> = [];

    dates = data
        .filter((item) => {
            return Vue.prototype.MgIsValidISOString(item.startTime);
        })
        .map((item) => {
            const dateFromIso = parseISO(item.startTime || "");
            const formattedDate = format(dateFromIso, "yyyy-MM-dd");

            return formattedDate;
        });

    dates = Array.from(new Set(dates));

    dates.forEach((date: string) => {
        const itemForRetunValue = {
            date: date,
            slots: data.filter((item) => {
                let dateFromIso;
                let formattedDate = null;

                if (Vue.prototype.MgIsValidISOString(item.startTime)) {
                    dateFromIso = parseISO(item.startTime || "");
                    formattedDate = format(dateFromIso, "yyyy-MM-dd");
                }

                return Boolean(date === formattedDate);
            })
        };

        returnValue.push(itemForRetunValue);
    });

    return returnValue;
}

@Module({
    dynamic: true,
    store: Store,
    name: "getAttendeeSchedule",
    namespaced: true
})
export default class GetAttendeeScheduleModule extends VuexModule {
    scheduleData: Array<AttendeeScheduleDay> = [];
    scheduleDataList: Array<AttendeeScheduleSlot> = [];
    favoriteSessions: Array<AttendeeScheduleSlot> = [];
    favoritePosters: Array<AttendeeScheduleSlot> = [];
    rsvpsToGet: Array<string> = [];
    scheduleAttendees: Array<AttendeeDataObject> = [];

    @Mutation
    public setRsvpsToGet(data: Array<string>) {
        this.rsvpsToGet = data;
    }

    @Mutation
    public setScheduleAttendees(data: Array<AttendeeDataObject>) {
        this.scheduleAttendees = data;
    }

    @Mutation
    public addScheduleAttendee(data: AttendeeDataObject) {
        if (Object.keys(data).length) {
            this.scheduleAttendees.push(data);
        }
    }

    @Mutation
    public setFavoriteSessions(data: Array<AttendeeScheduleSlot>) {
        this.favoriteSessions = data;
    }

    @Mutation
    public setFavoritePosters(data: Array<AttendeeScheduleSlot>) {
        this.favoritePosters = data;
    }

    @Mutation
    public setAttendeeSchedule(data: Array<AttendeeScheduleDay>) {
        this.scheduleData = data;
    }

    @Mutation
    public setAttendeeScheduleList(data: Array<AttendeeScheduleSlot>) {
        this.scheduleDataList = data;
    }

    @Action({})
    handleRsvps(data: Array<AttendeeScheduleSlot>) {
        let rsvps: Array<string> = [];

        data.map((item: AttendeeScheduleSlot) => {
            const confirmdedIds = item.networking?.confirmed || [];
            const pendingIds = item.networking?.pending || [];
            const declinedIds = item.networking?.declined || [];
            let hostId: Array<string> = [];

            if (item.networking?.hostId) {
                hostId = [item.networking.hostId];
            }

            rsvps = [
                ...rsvps,
                ...confirmdedIds,
                ...pendingIds,
                ...hostId,
                ...declinedIds
            ];
        });

        rsvps = Array.from(new Set(rsvps));
        this.setRsvpsToGet(rsvps);
    }

    @Action({ rawError: true })
    async getAttendeeSchedule(options: Record<string, string | boolean> = {}) {
        const token = this.context.rootGetters.idToken;
        const requestOptions = {
            headers: { Authorization: `bearer ${token}` },
            params: { only: "" }
        };

        if (options.only && "string" === typeof options.only) {
            requestOptions.params.only = options.only;
        }

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`${endpoint}`, requestOptions)
                .then((response) => {
                    const sourceData = response.data;
                    const data = sourceData.filter(
                        (item: AttendeeScheduleSlot) => {
                            let returnValue = true;

                            if ("past" !== requestOptions.params.only) {
                                returnValue = !item.onDemandOnly;
                            }

                            return returnValue;
                        }
                    );

                    this.setRsvpsToGet([]);

                    if (options.flatList) {
                        this.context.commit("setAttendeeScheduleList", data);
                    } else {
                        // first format
                        const formattedSchedule = formatSchedule(data);
                        this.handleRsvps(data);

                        // then commit
                        this.context.commit(
                            "setAttendeeSchedule",
                            formattedSchedule
                        );
                    }

                    return resolve(true);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ commit: "setFavoriteSessions", rawError: true })
    async getFavoriteSessions() {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`${endpoint}/sessions`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ commit: "setFavoritePosters", rawError: true })
    async getFavoritePosters() {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`${endpoint}/posters`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }
}
