<template>
    <div
        v-if="isSavingFavorite || isLoadingFavoritesLocal"
        key="saving"
        class="text-sm"
    >
        Saving...
    </div>
    <b-tooltip
        v-else-if="!isLoadingFavorites"
        position="is-left"
        :label="tooltipmsg"
    >
        <button
            @click="handleFavoriteClick"
            :class="[
                {
                    'text-red-500': isFavorited,
                    'text-fav': !isFavorited
                },
                buttonClass
            ]"
            class="bg-transparent p-0"
        >
            <font-awesome-icon aria-hidden="true" icon="heart" />
            <span class="sr-only">{{ tooltipmsg }}</span>
        </button>
    </b-tooltip>
</template>

<script>
import { getModule } from "vuex-module-decorators";
import store from "@/store";

import scheduleitemVuexModule from "@/store/vuex-modules/scheduleitem";
const scheduleitemStore = getModule(scheduleitemVuexModule);

import attendeeScheduleVuexModule from "@/store/vuex-modules/getAttendeeSchedule";
const attendeeScheduleStore = getModule(attendeeScheduleVuexModule);

import attendeeVuexModule from "@/store/vuex-modules/attendees";

const attendeeStore = getModule(attendeeVuexModule);

export default {
    name: "favorite-button",
    props: {
        isLoadingFavorites: Boolean,
        session: {
            type: Object,
            required: false,
            default() {
                return {};
            }
        },
        sessionType: {
            type: String,
            default: "sessions"
        },
        buttonClass: {
            type: String,
            default: "text-2xl"
        },
        tooltipmsg: {
            type: String,
            default: "Favorite this session"
        },
        handleAction: {
            type: String,
            default: "Session"
        },
        exhibitorId: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            componentId: `favorite-button-${this.MgGetUUID()}`,
            isLoadingFavoritesLocal: false,
            favoriteError: false,
            isSavingFavorite: false
        };
    },
    computed: {
        userInfo() {
            return store.getters.userInfo;
        },
        id() {
            return (
                this.session.sessionId ||
                this.session.id ||
                this.session.posterId
            );
        },
        isFavorited() {
            let returnValue = false;
            let favorites = [];

            if (this.handleAction === "exhibitor") {
                return attendeeStore.favoriteExhibitors.includes(
                    this.exhibitorId
                );
            }

            if ("posters" === this.sessionType) {
                favorites = this.myfavoritePosters;
            } else {
                favorites = this.myfavoriteSessions;
            }

            if (Array.isArray(favorites)) {
                const match = favorites.find((item) => {
                    return Boolean(this.id == item.id);
                });

                returnValue = Boolean(match);
            }

            return returnValue;
        },

        myfavoriteSessions() {
            return attendeeScheduleStore.favoriteSessions;
        },

        myfavoritePosters() {
            return attendeeScheduleStore.favoritePosters;
        }
    },
    methods: {
        handleFavoriteClick() {
            this.isSavingFavorite = true;
            let action;

            if (this.handleAction != "Session") {
                const payload = attendeeStore.attendee;

                const chckFavoriteExhibitor = attendeeStore.favoriteExhibitors.includes(
                    this.exhibitorId
                );

                if (chckFavoriteExhibitor) {
                    const newFavoritePayLoad = payload.favoriteExhibitors.filter(
                        (item) => {
                            return item != this.exhibitorId;
                        }
                    );
                    attendeeStore.setAttendeeUpdatePayload({
                        favoriteExhibitors: [...newFavoritePayLoad]
                    });
                } else {
                    if (payload.favoriteExhibitors) {
                        attendeeStore.setAttendeeUpdatePayload({
                            favoriteExhibitors: [
                                ...payload.favoriteExhibitors,
                                this.exhibitorId
                            ]
                        });
                    } else {
                        attendeeStore.setAttendeeUpdatePayload({
                            favoriteExhibitors: [this.exhibitorId]
                        });
                    }
                }

                attendeeStore
                    .putAttendee({ id: this.userInfo.id })
                    .catch(() => {
                        alert("Sorry, there was an error saving to favorites.");
                        this.favoriteError = true;
                    })
                    .finally(() => {
                        this.isSavingFavorite = false;
                    });

                return;
            }

            const payload = {
                type: this.sessionType,
                id: this.id
            };

            if (this.isFavorited) {
                action = scheduleitemStore.removeScheduleItem;
            } else {
                action = scheduleitemStore.putSchedule;
            }

            action(payload)
                .then(() => {
                    this.getFavorites();
                })
                .catch(() => {
                    alert("Sorry, there was an error saving to favorites.");
                    this.favoriteError = true;
                })
                .finally(() => {
                    this.isSavingFavorite = false;
                });
        },
        getFavorites() {
            let action;

            if ("posters" === this.sessionType) {
                action = attendeeScheduleStore.getFavoritePosters;
            } else {
                action = attendeeScheduleStore.getFavoriteSessions;
            }

            this.isLoadingFavoritesLocal = true;
            action().finally(() => {
                this.isLoadingFavoritesLocal = false;
            });
        }
    }
};
</script>
